<template>
  <v-main>
    <div class="text-center">
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">mail</p>
        {{ this.init.login_id }}
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">password</p>
        <v-text-field v-model="password_plain_text" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">name</p>
        <v-text-field v-model="name" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">nickname</p>
        <v-text-field v-model="nickname" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">zip</p>
        <v-text-field v-model="zip" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">prefecture</p>
        <v-text-field v-model="prefecture" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">birthday</p>
        <v-text-field v-model="birthday" single-line outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">gender</p>
        <v-text-field v-model="gender" single-line outlined dense></v-text-field>
      </v-col>
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">簡易会員登録
      </v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      init: this.storageGet('*'),
      name: null,
      nickname: null,
      password_plain_text: null,
      zip: null,
      prefecture: null,
      birthday: null,
      gender: null,
    };
  },
  mounted() {
  },
  methods: {
    async callApi() {
      try {

        // やまや共通ID発行API
        const yamaya_id_req = {
          'type': 'EC',
          'mail': this.init.login_id,
        };
        const yamaya_id_res = await this.callPfApiFromEcMock('/yamaya_id', yamaya_id_req);

        // アプリ会員情報更新API
        let user_req = {
          'type': 'EC',
          'membership_status': 1,
        };

        if (this.password_plain_text) {
          user_req.password_plain_text = this.password_plain_text
        }
        if (this.name) {
          user_req.name = this.name
        }
        if (this.nickname) {
          user_req.nickname = this.nickname
        }
        if (this.zip) {
          user_req.zip = this.zip
        }
        if (this.prefecture) {
          user_req.prefecture = this.prefecture
        }
        if (this.birthday) {
          user_req.birthday = this.birthday
        }
        if (this.gender) {
          user_req.gender = this.gender
        }
        user_req.membership_status = 1

        await this.callPfApiFromEcMock('/user/update/' + yamaya_id_res.yamaya_id, user_req);

        //ネイティブに戻す
        this.requestToNativeToShowTopPageAfterNewAccountOrLogin('new-account', yamaya_id_res.yamaya_id)
      } catch (e) {
        console.log(e);
        this.callDialog('エラー', e.message, 3);
      }
    }
  },
};
</script>

<style scoped>
.fontsize11 > > > label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
